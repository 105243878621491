export const prodHostname = 'app.custodynavigator.com';
export const testHostname = 'testapp.custodynavigator.com';
export const qaHostname = 'qaapp.testing.custodynavigator.com';

export enum ENV {
  DEV = 'DEV',
  QA = 'QA',
  TEST = 'TEST',
  PROD = 'PROD',
}
